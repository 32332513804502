$('#modal-rules').on('hidden.bs.modal', function () {
    $('#membershipapplyform-acceptrules').prop('checked', false);
})
$('#modal-rules .btn-primary').on('click', function() {
    $('#modal-rules').modal('hide');
    $('#membershipapplyform-acceptrules').prop('checked', true);
})
$('#membership-apply-form').on('submit', function(e) {
    $('#membership-apply-form button[name=submit-button]').prop('disabled', true);
    setTimeout(function() {
        $('#membership-apply-form button[name=submit-button]').prop('disabled', false);
    }, 15 * 1000)
})

setTimeout(function() {
    if($('#membership-apply-form').length > 0) {
        $('#modal-rules').modal('show');
    }
}, 2000)
